import api from '../Base/Config'

const fileContentType = (fileName) => {
    const arr = fileName.split('.')
    const fileExtension = arr[arr.length - 1]
    switch (fileExtension) {
        case 'txt':
            return 'text/plain'
        case 'jpg':
        case 'jpeg':
            return 'image/jpeg'
        case 'png':
            return 'image/png'
        case 'pdf':
            return 'application/pdf'
        case 'csv':
            return 'text/csv'
        default:
            return 'application/octet-stream'
    }
}

const openPreSignedUrlobj = async (key, service) => {
    try {
        const url = await getPreSignedUrlGetObj(key,service)
        window.open(url, '_blank')
    } catch (err) {
        console.log(err)
    }
}

const getPreSignedUrlPutObj = (key, contentType, service) => {
    return new Promise(async (res, rej) => {
        try {
            const resp = await api.get('/getpresignedurlputobj', {
                params: {
                    key,
                    contentType,
                    service
                },
            })
            if (resp?.data?.code === 100) res(resp?.data?.url)
            res(null)
        } catch (err) {
            console.log(err)
            rej(null)
        }
    })
}

const getPreSignedUrlGetObj = (key, service) => {
    return new Promise(async (res, rej) => {
        try {
            const resp = await api.get('/getpresignedurlgetobj', {
                params: {
                    key,
                    service
                },
            })
            if (resp?.data?.code === 100) res(resp?.data?.url)
            res(null)
        } catch (err) {
            console.log(err)
            rej(null)
        }
    })
}

export { fileContentType, getPreSignedUrlPutObj, getPreSignedUrlGetObj, openPreSignedUrlobj }
